import {
  METADATA_LINK_REL_VALUES,
  METADATA_OPENGRAPH_TYPES,
  METADATA_TWITTER_CARDS,
  setBasicMetadata,
} from '@iheartradio/web.remix-shared';
import type { ServerRuntimeMetaFunction } from '@remix-run/server-runtime';

import {
  METADATA_DEFAULT_CANONICAL,
  METADATA_DEFAULT_IMAGE,
  METADATA_GLOBAL_DESCRIPTION,
  METADATA_GLOBAL_KEYWORDS,
  METADATA_GLOBAL_TITLE,
} from '~app/utilities/constants';

export const defaultMeta: ServerRuntimeMetaFunction = () => {
  return [
    ...setBasicMetadata({
      title: METADATA_GLOBAL_TITLE,
      description: METADATA_GLOBAL_DESCRIPTION,
      keywords: METADATA_GLOBAL_KEYWORDS,
      image: METADATA_DEFAULT_IMAGE,
      type: METADATA_OPENGRAPH_TYPES.Website,
      card: METADATA_TWITTER_CARDS.Summary,
      url: 'https://listen.iheart.com', // TODO: app url generation should use a common function
    }),
    {
      tagName: 'link',
      rel: METADATA_LINK_REL_VALUES.Canonical,
      href: METADATA_DEFAULT_CANONICAL,
    },
    {
      tagName: 'link',
      rel: METADATA_LINK_REL_VALUES.ImageSource,
      href: METADATA_DEFAULT_IMAGE,
    },
  ];
};
